@font-face {
  font-family: "iyes";
  src: url("../fonts/iyesIcon.ttf?t=1649735859658") format("truetype");
}

.iyes {
  display: inline-block;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  font-family: "iyes";
  font-size: 14px;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iyes-wenjian:before {
  content: "\e80a";
}

.iyes-wodeerweima:before {
  content: "\e80b";
}

.iyes-wubeijing:before {
  content: "\e80c";
}

.iyes-caijianxuanzhuan:before {
  content: "\e80d";
}

.iyes-caidan:before {
  content: "\e80e";
}

.iyes-fasongshibai:before {
  content: "\e80f";
}

.iyes-caidan2:before {
  content: "\e810";
}

.iyes-fasongzhong:before {
  content: "\e811";
}

.iyes-fuzhi:before {
  content: "\e812";
}

.iyes-gou:before {
  content: "\e813";
}

.iyes-guanbi:before {
  content: "\e814";
}

.iyes-huifuyuyin:before {
  content: "\e815";
}

.iyes-huifu:before {
  content: "\e816";
}

.iyes-masaike:before {
  content: "\e817";
}

.iyes-jingyin:before {
  content: "\e818";
}

.iyes-nan:before {
  content: "\e819";
}

.iyes-nv:before {
  content: "\e81a";
}

.iyes-qingchu:before {
  content: "\e81b";
}

.iyes-quxiaojingyin:before {
  content: "\e81c";
}

.iyes-quxiaozhiding:before {
  content: "\e81d";
}

.iyes-qunzu:before {
  content: "\e81e";
}

.iyes-saoyisao:before {
  content: "\e81f";
}

.iyes-fujian:before {
  content: "\e820";
}

.iyes-shanchu:before {
  content: "\e821";
}

.iyes-shanchuduihua:before {
  content: "\e822";
}

.iyes-shouji:before {
  content: "\e823";
}

.iyes-shengyin:before {
  content: "\e824";
}

.iyes-tupianbianji-queren:before {
  content: "\e825";
}

.iyes-tupian:before {
  content: "\e826";
}

.iyes-tupianbianji-tuihui:before {
  content: "\e827";
}

.iyes-wenjian1:before {
  content: "\e828";
}

.iyes-weidu:before {
  content: "\e829";
}

.iyes-xiazai:before {
  content: "\e82a";
}

.iyes-xiala:before {
  content: "\e82b";
}

.iyes-xiangji:before {
  content: "\e82c";
}

.iyes-xuanze:before {
  content: "\e82d";
}

.iyes-yidu:before {
  content: "\e82e";
}

.iyes-zhidingliaotian:before {
  content: "\e82f";
}

.iyes-youshangjiaocaidan:before {
  content: "\e830";
}

.iyes-zhuanfa:before {
  content: "\e831";
}

.iyes-caijianxuanzhuan1:before {
  content: "\e832";
}

.iyes-fasongshibai1:before {
  content: "\e833";
}

.iyes-caidan21:before {
  content: "\e834";
}

.iyes-caidan1:before {
  content: "\e835";
}

.iyes-fujian1:before {
  content: "\e836";
}

.iyes-fasongzhong1:before {
  content: "\e837";
}

.iyes-fuzhi1:before {
  content: "\e838";
}

.iyes-guanbi1:before {
  content: "\e839";
}

.iyes-huifu1:before {
  content: "\e83a";
}

.iyes-gou1:before {
  content: "\e83b";
}

.iyes-huifuyuyin1:before {
  content: "\e83c";
}

.iyes-jingyin1:before {
  content: "\e83d";
}

.iyes-masaike1:before {
  content: "\e83e";
}

.iyes-qingchu1:before {
  content: "\e83f";
}

.iyes-nv1:before {
  content: "\e840";
}

.iyes-qunzu1:before {
  content: "\e841";
}

.iyes-quxiaojingyin1:before {
  content: "\e842";
}

.iyes-quxiaozhiding1:before {
  content: "\e843";
}

.iyes-saoyisao1:before {
  content: "\e844";
}

.iyes-shanchu1:before {
  content: "\e845";
}

.iyes-shanchuduihua1:before {
  content: "\e846";
}

.iyes-nan1:before {
  content: "\e847";
}

.iyes-shengyin1:before {
  content: "\e848";
}

.iyes-tupian1:before {
  content: "\e849";
}

.iyes-shouji1:before {
  content: "\e84a";
}

.iyes-weidu1:before {
  content: "\e84b";
}

.iyes-tupianbianji-queren1:before {
  content: "\e84c";
}

.iyes-wenjian2:before {
  content: "\e84d";
}

.iyes-tupianbianji-tuihui1:before {
  content: "\e84e";
}

.iyes-xiala1:before {
  content: "\e84f";
}

.iyes-xiazai1:before {
  content: "\e850";
}

.iyes-xuanze1:before {
  content: "\e851";
}

.iyes-yidu1:before {
  content: "\e852";
}

.iyes-youshangjiaocaidan1:before {
  content: "\e853";
}

.iyes-zhidingliaotian1:before {
  content: "\e854";
}

.iyes-xiangji1:before {
  content: "\e855";
}

.iyes-zhuanfa1:before {
  content: "\e856";
}

.iyes-wode:before {
  content: "\e857";
}

.iyes-lianxiren:before {
  content: "\e858";
}

.iyes-liaotian:before {
  content: "\e859";
}
