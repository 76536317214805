* {
  box-sizing: border-box;

  /* 隱藏scroll-bar */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

/* 隱藏scroll-bar */
*::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

html,
body {
  font-family: PingFangSC-Regular, sans-serif;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}


/* override antd mobile css */
:root:root {
  --adm-color-primary: #1b9cea;
}